import { Redirect, Route } from "react-router-dom";
import {
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import { Login, Layout } from './layout';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from './i18n/de';
import englishMessages from './i18n/en';
import customRoutes from './routes';
import themeReducer from './themeReducer';
import users from "./components/users";
import companies from "./components/company";
import products from "./components/product";
import storages from "./components/storage";
import stocks from "./components/stock";
import notifications from "./components/notification";
import dimensions from "./components/dimension";
import gearings from "./components/gearing";
import saws from "./components/saw";
import models from "./components/model";
import categories from "./components/category";
import specifications from "./components/specification";
import materialGroups from "./components/materialGroup";
import materials from "./components/material";
import decisions from "./components/decision";
import speeds from "./components/speed";
import tpms from "./components/tpm";
import emailTemplates from "./components/emailTemplate";
import { Dashboard } from './components/dashboard';
import NotFound from './NotFound';
import React from "react";


const lang = localStorage.getItem('lang');

const i18nProvider = polyglotI18nProvider(locale => {
    if(lang === 'en'){
        if (locale === 'de') {
            return import('./i18n/de').then(messages => messages.default);
        }
        return englishMessages;

    }else{
        if (locale === 'en') {
            return import('./i18n/en').then(messages => messages.default);
        }
        return germanMessages;
    }

},lang);

const entrypoint =process.env.REACT_APP_BACKEND_URL+'/api';
const fetchHeaders = (window.localStorage.getItem("token")) ? { Authorization: `Bearer ${window.localStorage.getItem("token")}` }: null;
const h =  (fetchHeaders) ? { headers:new Headers(fetchHeaders)} :{};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: (fetchHeaders) ? new Headers(fetchHeaders) :'',
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, h)
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                    localStorage.clear();
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                return window.localStorage.getItem("token")  ? window.location.reload() : <Redirect to="/login" />
                            }} />
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const myDataProvider = {
    ...dataProvider,
    create:(resource, params)=>{
        if (resource === 'materials' ) {
            if(params['data']['boehler'] === undefined || params['data']['boehler'] === null)
                params['data']['boehler'] =  "";
            if(params['data']['din'] === undefined || params['data']['din'] === null)
                params['data']['din'] =  "";
            if(params['data']['number'] === undefined || params['data']['number'] === null)
                params['data']['number'] =  "";
        }
        return dataProvider.create(resource, params);
    },
    update: (resource, params) => {
        if (resource === 'materials' ) {
            if(params['data']['boehler'] === undefined || params['data']['boehler'] === null)
                params['data']['boehler'] =  "";
            if(params['data']['din'] === undefined || params['data']['din'] === null)
                params['data']['din'] =  "";
            if(params['data']['number'] === undefined || params['data']['number'] === null)
                params['data']['number'] =  "";
        }
        return dataProvider.update(resource, params);
    },
    // getList: (resource, params) => {
    //     console.log(resource)
    //     console.log(params)
    //     // let q = ( params.filter.q) ? params.filter.q.split(' '): "";
    //     // if(resource == "")
    //     if(params.filter.q){
    //         let q = params.filter.q.split(' ');
    //         console.log(q);
    //         delete params.filter['q'];
    //         if(q.length > 1) {
    //             params.filter.firstName = q[0];
    //             params.filter.lastName = q[1];
    //         }else{
    //             params.filter.firstName = q[0];
    //             params.filter.lastName = q[0];
    //         }
    //     }
    //
    //     console.log(dataProvider.getList(resource, params))
    //     return dataProvider.getList(resource, params);
    // },
};
export default (props) => {
    return(
    <HydraAdmin
                dataProvider={ myDataProvider }
                authProvider={ authProvider}
                entrypoint={ entrypoint }
                customReducers={{ theme: themeReducer}}
                layout={Layout}
                loginPage={Login}
                i18nProvider={i18nProvider}
                customRoutes={customRoutes}
                dashboard={Dashboard}
                catchAll={NotFound}
    >
        <ResourceGuesser name="users" {...users} />
        <ResourceGuesser name="companies" {...companies} />
        <ResourceGuesser name="products" {...products} />
        <ResourceGuesser name="storages" {...storages} />
        <ResourceGuesser name="stocks" {...stocks} />
        <ResourceGuesser name="notifications" {...notifications} />
        <ResourceGuesser name="customers" {...users} />
        <ResourceGuesser name="admins" {...users} />
        <ResourceGuesser name="dimensions" {...dimensions} />
        <ResourceGuesser name="gearings" {...gearings} />
        <ResourceGuesser name="saws" {...saws} />
        <ResourceGuesser name="models" {...models} />
        <ResourceGuesser name="categories" {...categories} />
        <ResourceGuesser name="specifications" {...specifications} />
        <ResourceGuesser name="material_groups" {...materialGroups} />
        <ResourceGuesser name="materials" {...materials} />
        <ResourceGuesser name="decisions" {...decisions} />
        <ResourceGuesser name="speeds" {...speeds} />
        <ResourceGuesser name="tpms" {...tpms} />
        <ResourceGuesser name="email_templates" {...emailTemplates} />
    </HydraAdmin>
)};