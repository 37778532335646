import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField, Datagrid, useTranslate, Show, ReferenceInput, SelectInput,
    ReferenceField, NumberField, NumberInput, BooleanField, BooleanInput, AutocompleteInput, FunctionField,
    useGetOne
} from 'react-admin';
import React from "react";

const DecisionTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];
const ItemPerPage = 99999999;
const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
const OptionTextCustom= ({record}) =>{
    // return(
    //     ['HE-A','HE-B','HE-M','IP-E','L','U'].includes(record.type) ?
    //             <span>{record.type}{' ['}{record.height}{', '}{record.width}{']'}</span>
    //             :
    //             ['Vierkantrohr','Rohr'].includes(record.type) ?
    //                 <span>{record.type}{' (['}{record.outerDiameterFrom}{', '}{record.outerDiameterTo}{'] / ['}{record.wallThicknessFrom}{','}{record.wallThicknessTo}{'])'}</span>
    //                 :
    //                 <span>{record.type}{' ['}{record.thicknessFrom}{', '}{record.thicknessTo}{']'}</span>
    //
    // )
    return SpecificationRender(record)
}
const SpecificationRender = (record) =>{
    // const { data, loading, error } = useGetOne('specifications', record.specification);
    // record = data
    // console.log(record);
    return(
        ['HE-A','HE-B','HE-M','IP-E','L','U'].includes(record.type) ?
            `${record.type}' ['${record.height}', '${record.width}']'`
            :
            ['Vierkantrohr','Rohr'].includes(record.type) ?
                `${record.type} ([${record.outerDiameterFrom}, ${record.outerDiameterTo}] / [${record.wallThicknessFrom},${record.wallThicknessTo}])`
                :
                `${record.type} [${record.thicknessFrom}, ${record.thicknessTo}]`

    )
}

const RenderShow = (record)=>{
    const { data, loading, error } = useGetOne('specifications', record.specification);
    if(data){
        return ['HE-A','HE-B','HE-M','IP-E','L','U'].includes(record.type) ?
            `${data.type}' ['${data.height}', '${data.width}']'`
            :
            ['Vierkantrohr','Rohr'].includes(data.type) ?
                `${data.type} ([${data.outerDiameterFrom}, ${data.outerDiameterTo}] / [${data.wallThicknessFrom},${data.wallThicknessTo}])`
                :
                `${data.type} [${data.thicknessFrom}, ${data.thicknessTo}]`;
    }
    return "";

}

export  const DecisionCreate = props => {
    return (
        <Create {...props}
                title={<DecisionTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput  source={"name"} addLabel={true} />
                <BooleanInput  source={"fullMaterial"} label='VollMaterial' />
                <BooleanInput  source={"profile"}  label='Profile' />
                <ReferenceInput reference="dimensions" source="dimension"  label='Maße'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="material_groups" source="materialGroup"  label='Materialgruppe'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="specifications" source="specification"  label='spezifikation'
                                filterToQuery={searchText => ({ title: searchText })} perPage={999999999999999999}>
                    {/*<SelectInput validate={requiredValidate} optionText='type' />*/}
                    <AutocompleteInput  validate={requiredValidate}
                                        optionText={<OptionTextCustom />}
                                        inputText={SpecificationRender}
                                        matchSuggestion={(filterValue, suggestion) => true}
                    />
                </ReferenceInput>
                <ReferenceInput reference="products" source="product"  label='Produkt'>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="gearings" source="gearing"  label='Verzahnug'>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="categories" source="category"  label='Kategorie'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>
                <TextInput  source={"surface"}  />
            </SimpleForm>
        </Create>
    )
};
export  const DecisionEdit = props => {
    return (
        <Edit {...props}
              title={<DecisionTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput  source={"name"} addLabel={true} />
                <BooleanInput  source={"fullMaterial"} label='VollMaterial' />
                <BooleanInput  source={"profile"}  label='Profile' />
                <ReferenceInput reference="dimensions" source="dimension"  label='Maße'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="material_groups" source="materialGroup"  label='Materialgruppe'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="specifications" source="specification"  label='spezifikation'
                                filterToQuery={searchText => ({ title: searchText })} perPage={999999999999999999}>

                    {/*<SelectInput validate={requiredValidate} optionText='type' />*/}
                    {/*<SelectInput validate={requiredValidate} optionText={<OptionTextCustom />} />*/}
                    <AutocompleteInput  validate={requiredValidate}
                                        optionText={<OptionTextCustom />}
                                        inputText={SpecificationRender}
                                        matchSuggestion={(filterValue, suggestion) => true}
                    />
                </ReferenceInput>
                <ReferenceInput reference="products" source="product"  label='Produkt'>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="gearings" source="gearing"  label='Verzahnug'>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="categories" source="category"  label='Kategorie'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>
                <TextInput  source={"surface"} />
            </SimpleForm>
        </Edit>
    )
};
export const DecisionShow = props => {
    return(
        <Show {...props} title={<DecisionTitle/>} >
            <SimpleShowLayout >
                <TextField  source={"name"} addLabel={true} />
                <BooleanField  source={"fullMaterial"}  label='Vollmaterial' />
                <BooleanField  source={"profile"}  label='Profile' />
                <ReferenceField reference="dimensions" source="dimension"  label='Maße'>
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField reference="material_groups" source="materialGroup"  label='materialgruppe'>
                    <TextField source='name' />
                </ReferenceField>
                {/*<ReferenceField reference="specifications" source="specification"  label='spezifikation'>*/}
                {/*    <TextField source='type' />*/}
                {/*</ReferenceField>*/}
                <FunctionField resource="specifications"  label='spezifikation' render={RenderShow}/>
                <ReferenceField reference="products" source="product"  label='Produkt'>
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField reference="gearings" source="gearing"  label='Verzahnug'>
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField reference="categories" source="category"  label='Kategorie'>
                    <TextField  optionText='name' />
                </ReferenceField>
                <NumberField  source={"surface"} addLabel={true}/>
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn placeholder={translate('resources.decisions.fields.name')}/>
            <ReferenceInput perPage={ItemPerPage} reference="dimensions" source="dimension">
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="material_groups" source="materialGroup">
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="gearings" source="gearing">
                <SelectInput optionText='name' />
            </ReferenceInput>
        </Filter>
    )
};

export  const DecisionsList = props => {
    return(
        <List {...props}
              hasEdit={true}
              hasShow={true}
              exporter={false}
              filters={<SearchFilter />}
              sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField  source={"name"} addLabel={true} />
                <BooleanField  source={"fullMaterial"} label='Vollmaterial' />
                <BooleanField  source={"profile"} label='Profile' />
                <ReferenceField reference="dimensions" source="dimension"  label={('resources.dimensions.fields.name')} sortBy={'dimension.name'}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField reference="material_groups" source="materialGroup"  label='Materialgruppe' sortBy={'materialGroup.name'}>
                    <TextField source='name' />
                </ReferenceField>
                {/*<ReferenceField reference="specifications" source="specification"  label='Spezifikation'>*/}
                {/*    <TextField source='type' />*/}
                {/*</ReferenceField>*/}
                <FunctionField resource="specifications"  label='spezifikation' render={RenderShow}  sortBy={'specification.type'}/>
                <ReferenceField reference="products" source="product"  label='Produkt' sortBy={'product.name'}>
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField reference="gearings" source="gearing"  label='Verzahnug'  sortBy={'gearing.name'}>
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField reference="categories" source="category"  label='Kategorie' sortBy={'category.name'}>
                    <TextField  source='name' />
                </ReferenceField>
                <NumberField  source={"surface"} />

                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
