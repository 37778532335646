import {
    SimpleForm,
    FileField,
    FileInput, Title, useTranslate,
    useNotify, SaveButton, Toolbar, required
} from 'react-admin';
import React,{ useState }  from "react";
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CircularProgress } from '@material-ui/core';


const requiredValidate = [required()];


const CatalogUpload = () => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();


    const ToolbarUpload = props => {
        return <Toolbar {...props} >
            <SaveButton
                label="hochladen"
                icon={loading ? <CircularProgress color="success" /> : <CloudUploadIcon/>}
            />
        </Toolbar>
    };

    const SubmitHandel = (record) =>{
        setLoading(true);
        const formData = new FormData();
        if(record.catalogDe)
            formData.append('catalogDe', record.catalogDe.rawFile);
        if(record.catalogEn)
            formData.append('catalogEn', record.catalogEn.rawFile);
        formData.append('token', window.localStorage.getItem("token"));

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/catalog-upload`,formData)
            .then((res)=>{
                setLoading(false);
                notify('pos.catalog_upload.successMsg',{ type: 'success' })
            })
            .catch((err)=>{
                setLoading(false);
                notify(err,'error');
            });
    }

    return (
        <Card>
            <Title title={translate('resources.catalog_upload.name')} />
            <CardContent>
                <SimpleForm onSubmit={SubmitHandel} save={SubmitHandel} toolbar={<ToolbarUpload />}>
                    <FileInput source="catalogDe" label={translate('resources.catalog_upload.labelDe')} accept="application/pdf" validate={requiredValidate}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <FileInput source="catalogEn" label={translate('resources.catalog_upload.labelEn')} accept="application/pdf" validate={requiredValidate}>
                        <FileField source="src" title="title" />
                    </FileInput>
                </SimpleForm>
            </CardContent>
        </Card>
    )
};
export default CatalogUpload;

