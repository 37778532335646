import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField, Datagrid, useTranslate, Show,
    EmailField,
    NumberField,
    NumberInput, BooleanInput
} from 'react-admin';

const CompanyTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(100)];

export  const CompanyCreate = props => {
    return (
        <Create {...props}
                title={<CompanyTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={NameValidate}/>
                <TextInput source={"number"} validate={NameValidate}/>
                <TextInput source={"email"} validate={NameValidate}/>
                <TextInput source={"street"} />
                <TextInput source={"city"} />
                <TextInput source={"zip"} />
                <TextInput source={"country"} />
                <TextInput source={"phone"} />
            </SimpleForm>
        </Create>
    )
};

export  const CompanyEdit = props => {
    return (
        <Edit {...props}
                title={<CompanyTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"number"} validate={NameValidate}/>
                <TextInput source={"name"} validate={NameValidate}/>
                <TextInput source={"email"} validate={NameValidate}/>
                <TextInput source={"street"} />
                <TextInput source={"city"} />
                <TextInput source={"zip"} />
                <TextInput source={"country"} />
                <TextInput source={"phone"} />
            </SimpleForm>
        </Edit>
    )
};

export const CompanyShow = props => {
    return(
        <Show {...props} title={<CompanyTitle/>} >
            <SimpleShowLayout >
                <TextField source={"number"} addLabel={true}/>
                <TextField source={"name"} addLabel={true} />
                <EmailField source={"email"} addLabel={true} />
                <TextField source={"street"} addLabel={true} />
                <TextField source={"city"} addLabel={true} />
                <TextField source={"zip"} addLabel={true} />
                <TextField source={"country"} addLabel={true} />
                <TextField source={"phone"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
};

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


const SearchFilter = props => {
    const translate = useTranslate();
    return(
        <Filter {...props} >
            <SearchInput source="name" alwaysOn placeholder={translate('resources.companies.fields.name')}/>
            <TextInput source="number" />
            <TextInput source="email" />
            <TextInput source="phone" />
            <TextInput source="street" />
            <TextInput source="zip" />
            <TextInput source="city" />
            <TextInput source="country" />
        </Filter>
    )};
export  const CompaniesList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField source={"number"} addLabel={true}/>
                <TextField source="name" />
                <EmailField source="email" />
                <TextField source="street" />
                <TextField source="city" />
                <TextField source="zip" />
                <TextField source="country" />
                <TextField source="phone" />
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
