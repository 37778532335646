import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required,List,
    Create, TextField, Datagrid, useTranslate, Show,
    SelectInput,ReferenceField, ReferenceInput
} from 'react-admin';

const MaterialTitle = ({ record }) => <span>{record.name}</span>;
// const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export  const MaterialCreate = props => {
    return (
        <Create {...props}
                title={<MaterialTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"Name"} />
                <TextInput source={"nameEn"} />
                {/*<TextInput source={"number"} validate={requiredValidate}/>*/}
                <TextInput source={"number"} />
                <TextInput source={"din"} />
                <TextInput source={"skill"} />
                <TextInput source={"astmSaeAisi"} />
                <TextInput source={"gost"} />
                <TextInput source={"jis"} />
                <TextInput source={"bs"} />
                <TextInput source={"ss"} />
                <TextInput source={"euroNorm"} />
                <TextInput source={"afnor"} />
                <TextInput source={"une"} />
                <TextInput source={"uni"} />
                <TextInput source={"uns"} />
                <TextInput source={"boehler"} />
                <TextInput source={"dillingerHuette"} />
                <TextInput source={"hkm"} />
                <TextInput source={"outokumpo"} />
                <TextInput source={"ovako"} />
                <TextInput source={"sandvik"} />
                <TextInput source={"salzgitter"} />
                <TextInput source={"specialMetals"} />
                <TextInput source={"ssab"} />
                <TextInput source={"thyssen_krupp"} />
                <TextInput source={"uddeholm"} />
                <TextInput source={"metrialGroupId"} />
                <ReferenceInput reference="material_groups" source="MetrialGroup"  label='Materialgruppe'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>

            </SimpleForm>
        </Create>
    )
};

export  const MaterialEdit = props => {
    return (
        <Edit {...props}
                title={<MaterialTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"Name"} />
                <TextInput source={"nameEn"} />
                <TextInput source={"number"} />
                <TextInput source={"din"}/>
                <TextInput source={"skill"} />
                <TextInput source={"astmSaeAisi"} />
                <TextInput source={"gost"} />
                <TextInput source={"jis"} />
                <TextInput source={"bs"} />
                <TextInput source={"ss"} />
                <TextInput source={"euroNorm"} />
                <TextInput source={"afnor"} />
                <TextInput source={"une"} />
                <TextInput source={"uni"} />
                <TextInput source={"uns"} />
                <TextInput source={"boehler"} />
                <TextInput source={"dillingerHuette"} />
                <TextInput source={"hkm"} />
                <TextInput source={"outokumpo"} />
                <TextInput source={"ovako"} />
                <TextInput source={"sandvik"} />
                <TextInput source={"salzgitter"} />
                <TextInput source={"specialMetals"} />
                <TextInput source={"ssab"} />
                <TextInput source={"thyssen_krupp"} />
                <TextInput source={"uddeholm"} />
                <TextInput source={"metrialGroupId"} />
                <ReferenceInput reference="material_groups" source="MetrialGroup"  label='Materialgruppe'>
                    <SelectInput  optionText='name' />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const MaterialShow = props => {
    return(
        <Show {...props} title={<MaterialTitle/>} >
            <SimpleShowLayout >
                <TextField source={"Name"} />
                <TextField source={"nameEn"} />
                <TextField source={"number"} />
                <TextField source={"din"} />
                <TextField source={"skill"} />
                <TextField source={"astmSaeAisi"} />
                <TextField source={"gost"} />
                <TextField source={"jis"} />
                <TextField source={"bs"} />
                <TextField source={"ss"} />
                <TextField source={"euroNorm"} />
                <TextField source={"afnor"} />
                <TextField source={"une"} />
                <TextField source={"uni"} />
                <TextField source={"uns"} />
                <TextField source={"boehler"} />
                <TextField source={"dillingerHuette"} />
                <TextField source={"hkm"} />
                <TextField source={"outokumpo"} />
                <TextField source={"ovako"} />
                <TextField source={"sandvik"} />
                <TextField source={"salzgitter"} />
                <TextField source={"specialMetals"} />
                <TextField source={"ssab"} />
                <TextField source={"thyssen_krupp"} />
                <TextField source={"uddeholm"} />
                <TextField source={"metrialGroupId"} />
                <ReferenceField reference="material_groups" source="MetrialGroup" link={false} label='Materialgruppe'>
                    <TextField source="name" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="Name" alwaysOn placeholder={translate('resources.materials.fields.Name')}/>
            <TextInput source={"nameEn"} />
            <TextInput source={"number"} />
            <TextInput source={"din"} />
            <ReferenceInput reference="material_groups" source="MetrialGroup"  label='Materialgruppe'>
                <SelectInput  optionText='name' />
            </ReferenceInput>
            <TextInput source={"skill"} />
            <TextInput source={"astmSaeAisi"} />
            <TextInput source={"gost"} />
            <TextInput source={"jis"} />
            <TextInput source={"bs"} />
            <TextInput source={"ss"} />
            <TextInput source={"euroNorm"} />
            <TextInput source={"afnor"} />
            <TextInput source={"une"} />
            <TextInput source={"uni"} />
            <TextInput source={"uns"} />
            <TextInput source={"boehler"} />
            <TextInput source={"dillingerHuette"} />
            <TextInput source={"hkm"} />
            <TextInput source={"outokumpo"} />
            <TextInput source={"ovako"} />
            <TextInput source={"sandvik"} />
            <TextInput source={"salzgitter"} />
            <TextInput source={"specialMetals"} />
            <TextInput source={"ssab"} />
            <TextInput source={"thyssen_krupp"} />
            <TextInput source={"uddeholm"} />
        </Filter>
    )
};
export  const MaterialsList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'Name', order: 'ASC' }}
        >
            <Datagrid expand={<MaterialShow />} >
                <ReferenceField reference="material_groups" source="MetrialGroup" link={false} label='Materialgruppe' sortBy={'MetrialGroup.name'}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source={"number"} addLabel={true} />
                <TextField source={"Name"} addLabel={true}/>
                <TextField source={"nameEn"} addLabel={true}/>
                <TextField source={"din"} addLabel={true}/>
                {/*<TextField source={"skill"} addLabel={true}/>*/}
                {/*<TextField source={"astmSaeAisi"} addLabel={true}/>*/}
                {/*<TextField source={"gost"} addLabel={true}/>*/}
                {/*<TextField source={"jis"} addLabel={true}/>*/}
                {/*<TextField source={"bs"} addLabel={true}/>*/}
                {/*<TextField source={"ss"} addLabel={true}/>*/}
                {/*<TextField source={"euroNorm"} addLabel={true}/>*/}
                {/*<TextField source={"afnor"} addLabel={true}/>*/}
                {/*<TextField source={"une"} addLabel={true}/>*/}
                {/*<TextField source={"uni"} addLabel={true}/>*/}
                {/*<TextField source={"uns"} addLabel={true}/>*/}
                {/*<TextField source={"boehler"} addLabel={true}/>*/}
                {/*<TextField source={"dillingerHuette"} addLabel={true}/>*/}
                {/*<TextField source={"hkm"} addLabel={true}/>*/}
                {/*<TextField source={"outokumpo"} addLabel={true}/>*/}
                {/*<TextField source={"ovako"} addLabel={true}/>*/}
                {/*<TextField source={"sandvik"} addLabel={true}/>*/}
                {/*<TextField source={"salzgitter"} addLabel={true}/>*/}
                {/*<TextField source={"specialMetals"} addLabel={true}/>*/}
                {/*<TextField source={"ssab"} addLabel={true}/>*/}
                {/*<TextField source={"thyssen_krupp"} addLabel={true}/>*/}
                {/*<TextField source={"uddeholm"} addLabel={true}/>*/}
                {/*<TextField source={"metrialGroupId"} addLabel={true}/>*/}

                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
