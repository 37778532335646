import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, List,
    Create, TextField,  Datagrid, useTranslate,Show,ReferenceInput,SelectInput,
    ReferenceField,NumberField,NumberInput
} from 'react-admin';
import React from "react";

const TpmTitle = ({ record }) => <span>{record.name}</span>;
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
const ItemPerPage = 99999999;
export  const TpmCreate = props => {
    const translate = useTranslate();
    return (
        <Create {...props}
                title={<TpmTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <NumberInput  source={"tpm"} validate={requiredValidate}/>
                <ReferenceInput perPage={ItemPerPage} reference="gearings" source="gearing" >
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="products" source="product" label={translate('resources.products.fields.name')} >
                    <SelectInput  optionText='name'/>
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="categories" source="category" >
                    <SelectInput validate={requiredValidate} optionText='name'/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};

export  const TpmEdit = props => {
    const translate = useTranslate();
    return (
        <Edit {...props}
                title={<TpmTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <NumberInput  source={"tpm"} validate={requiredValidate}/>
                <ReferenceInput perPage={ItemPerPage} reference="gearings" source="gearing" >
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="products" source="product" label={translate('resources.products.fields.name')} >
                    <SelectInput  optionText='name'/>
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="categories" source="category" >
                    <SelectInput validate={requiredValidate} optionText='name'/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const TpmShow = props => {
    const translate = useTranslate();
    return(
        <Show {...props} title={<TpmTitle/>} >
            <SimpleShowLayout >
                <NumberField  source={"tpm"} />
                <ReferenceField  reference="gearings" source="gearing" >
                     <TextField source='name' />
                </ReferenceField>
                <ReferenceField  reference="products" source="product" label={translate('resources.products.fields.name')} >
                     <TextField source='name'/>
                </ReferenceField>
                <ReferenceField reference="categories" source="category" >
                     <TextField source='name'/>
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="tpm" alwaysOn placeholder={translate('resources.tpms.fields.tpm')}/>
            {/*<NumberInput  source={"teeth"} validate={requiredValidate}/>*/}
            <ReferenceInput perPage={ItemPerPage} reference="gearings" source="gearing" >
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="products" source="product" label={translate('resources.products.fields.name')} >
                <SelectInput optionText='name'/>
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="categories" source="category" >
                <SelectInput optionText='name'/>
            </ReferenceInput>
        </Filter>
    )
};
export  const TpmsList = props => {
    const translate = useTranslate();
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <NumberField  source={"tpm"} />
                <ReferenceField  reference="gearings" source="gearing" sortBy={'gearing.name'}>
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField  reference="products" source="product" label={translate('resources.products.fields.name')} sortBy={'product.name'}>
                    <TextField source='name'/>
                </ReferenceField>
                <ReferenceField reference="categories" source="category"  sortBy={'category.name'}>
                    <TextField source='name'/>
                </ReferenceField>

                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
