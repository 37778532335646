import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,ReferenceInput,SelectInput,
    ReferenceField
} from 'react-admin';

const StorageTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(100)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const StorageCreate = props => {
    return (
        <Create {...props}
                title={<StorageTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={NameValidate}/>
                <TextInput source={"location"} validate={NameValidate}/>
                <ReferenceInput reference="companies"
                                source="company" >
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};

export  const StorageEdit = props => {
    return (
        <Edit {...props}
                title={<StorageTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={NameValidate}/>
                <TextInput source={"location"} validate={NameValidate}/>
                <ReferenceInput reference="companies"
                                source="company">
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const StorageShow = props => {
    return(
        <Show {...props} title={<StorageTitle/>} >
            <SimpleShowLayout >
                <TextField source={"name"} addLabel={true} />
                <TextField source={"location"} addLabel={true} />
                <ReferenceField reference="companies" source="company" link={false}>
                    <TextField source="name" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn placeholder={translate('resources.storages.fields.name')}/>
            <TextInput source={"location"} />
        </Filter>
    )
};
export  const StoragesList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField source={"name"} addLabel={true} />
                <TextField source={"location"} addLabel={true} />
                <ReferenceField reference="companies" source="company" link={false} sortBy={'company.name'}>
                    <TextField source="name" />
                </ReferenceField>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
