import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,ReferenceInput,SelectInput,
    ReferenceField,NumberField,NumberInput
} from 'react-admin';


const StockTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const StockCreate = props => {
    return (
        <Create {...props}
                title={<StockTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <ReferenceInput reference="storages"
                             source="storage">
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="products"
                                source="product">
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <NumberInput source={"stock"} validate={requiredValidate}/>
                <NumberInput source={"minStock"} validate={requiredValidate}/>
                <NumberInput source={"minOrder"} validate={requiredValidate}/>
            </SimpleForm>
        </Create>
    )
};

export  const StockEdit = props => {
    return (
        <Edit {...props}
                title={<StockTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <ReferenceInput reference="storages"
                                source="storage">
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput reference="products"
                                source="product">
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <NumberInput source={"stock"} validate={requiredValidate}/>
                <NumberInput source={"minStock"} validate={requiredValidate}/>
                <NumberInput source={"minOrder"} validate={requiredValidate}/>
            </SimpleForm>
        </Edit>
    )
};

export const StockShow = props => {
    return(
        <Show {...props} title={<StockTitle/>} >
            <SimpleShowLayout >
                <ReferenceField reference="storages" source="storage" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField reference="products" source="product" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <NumberField  source={"stock"} addLabel={true} />
                <NumberField  source={"minStock"} addLabel={true} />
                <NumberField  source={"minOrder"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="stock" alwaysOn placeholder={translate('resources.stocks.fields.stock')}/>
            <ReferenceInput reference="storages"
                            source="storage">
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput reference="products"
                            source="product">
                <SelectInput optionText='name' />
            </ReferenceInput>
            <NumberInput source={"minStock"} />
            <NumberInput source={"minOrder"} />
        </Filter>
    )
};
export  const StocksList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <ReferenceField reference="storages" source="storage" link={false} sortBy={'storage.name'}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField reference="products" source="product" link={false} sortBy={'product.name'}>
                    <TextField source="name" />
                </ReferenceField>
                <NumberField  source={"stock"} addLabel={true} />
                <NumberField  source={"minStock"} addLabel={true} />
                <NumberField  source={"minOrder"} addLabel={true} />
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
