import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,ReferenceInput,SelectInput,
    ReferenceField,NumberField,NumberInput,BooleanField,BooleanInput,AutocompleteInput
} from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const ModelTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const ModelCreate = props => {
    const translate = useTranslate();
    return (
        <Create {...props}
                title={<ModelTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput  source={"name"}  />
                <ReferenceInput reference="saws" source="saw" label={translate('resources.saws.fields.manufacturer')}
                                filterToQuery={searchText => ({ title: searchText })} perPage={999999999999999999}>
                    <AutocompleteInput  validate={requiredValidate} optionText='manufacturer' />
                </ReferenceInput>
                {/*<ReferenceInput reference="dimensions" source="dimension"  label={translate('resources.saws.fields.manufacturer')}>
                    <SelectInput validate={requiredValidate} optionText='height' />
                </ReferenceInput>
                <ReferenceInput reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.strength')}>
                    <SelectInput validate={requiredValidate} optionText='strength' />
                </ReferenceInput>*/}
                <ReferenceInput reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.name')}  perPage={999999999999999999}>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <NumberInput  source={"length"} validate={requiredValidate} />
                <BooleanInput  source={"hm"} />
                <NumberInput  source={"factor"} validate={requiredValidate}/>
            </SimpleForm>
        </Create>
    )
};

export  const ModelEdit = props => {
    const translate = useTranslate();
    return (
        <Edit {...props}
                title={<ModelTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput  source={"name"} />
                <ReferenceInput reference="saws" source="saw" label={translate('resources.saws.fields.manufacturer')}
                                filterToQuery={searchText => ({ title: searchText })} perPage={999999999999999999}>
                    <AutocompleteInput  validate={requiredValidate} optionText='manufacturer' />
                </ReferenceInput>
                {/*<ReferenceInput reference="dimensions" source="dimension"   label={translate('resources.dimensions.fields.height')}>
                    <SelectInput validate={requiredValidate} optionText='height' />
                </ReferenceInput>
                <ReferenceInput reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.strength')}>
                    <SelectInput validate={requiredValidate} optionText='strength' />
                </ReferenceInput>*/}
                <ReferenceInput reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.name')} perPage={999999999999999999}>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <NumberInput  source={"length"} validate={requiredValidate} />
                <BooleanInput  source={"hm"} />
                <NumberInput  source={"factor"}  validate={requiredValidate}/>
            </SimpleForm>
        </Edit>
    )
};

export const ModelShow = props => {
    const translate = useTranslate();
    return(
        <Show {...props} title={<ModelTitle/>} >
            <SimpleShowLayout >
                <TextField  source={"name"} />
                <ReferenceField reference="saws" source="saw" label={translate('resources.saws.fields.manufacturer')}>
                    <TextField source="manufacturer" />
                </ReferenceField>
                <ReferenceField reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.height')} >
                    <TextField source="height" />
                </ReferenceField>
                <ReferenceField reference="dimensions" source="dimension"  label={translate('resources.dimensions.fields.strength')}>
                    <TextField source="strength" />
                </ReferenceField>
                <NumberField  source={"length"} addLabel={true} />
                <BooleanField  source={"hm"} addLabel={true}  />
                <NumberField  source={"factor"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn placeholder={translate('resources.models.fields.name')}/>
            {/*<ReferenceInput reference="saws" source="saw" label={translate('resources.saws.fields.manufacturer')} >*/}
            {/*    <SelectInput optionText='manufacturer' />*/}
            {/*</ReferenceInput>*/}
            <ReferenceInput reference="saws" source="saw" label={translate('resources.saws.fields.manufacturer')}
                            filterToQuery={searchText => ({ title: searchText })} perPage={999999999999999999}>
                <AutocompleteInput  optionText='manufacturer' />
            </ReferenceInput>
            <ReferenceInput reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.name')}
                            filterToQuery={searchText => ({ title: searchText })} perPage={999999999999999999}>
                <AutocompleteInput  optionText='name' />
            </ReferenceInput>
            {/*<ReferenceInput reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.name')} perPage={999999999999999999}>*/}
            {/*    <SelectInput optionText='name' />*/}
            {/*</ReferenceInput>*/}
            {/*<ReferenceInput reference="dimensions" source="dimension"   label={translate('resources.dimensions.fields.height')}>*/}
            {/*    <SelectInput optionText='height' />*/}
            {/*</ReferenceInput>*/}
            {/*<ReferenceInput reference="dimensions" source="dimension" label={translate('resources.dimensions.fields.strength')}>*/}
            {/*    <SelectInput optionText='strength' />*/}
            {/*</ReferenceInput>*/}
            <NumberInput  source={"length"} />
            <BooleanInput  source={"hm"}  />
            <NumberInput  source={"factor"} />
        </Filter>
    )
};
export  const ModelsList = props => {
    const translate = useTranslate();
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField  source={"name"} addLabel={true} />
                <ReferenceField reference="saws" source="saw" link={false} label={translate('resources.saws.fields.manufacturer')}>
                    <TextField source="manufacturer" />
                </ReferenceField>
                <ReferenceField  reference="dimensions" source="dimension" link={false} label={translate('resources.dimensions.fields.height')} >
                    <TextField source="height" />
                </ReferenceField>
                <ReferenceField reference="dimensions" source="dimension" link={false} label={translate('resources.dimensions.fields.strength')}>
                    <TextField source="strength" />
                </ReferenceField>
                <NumberField  source={"length"} addLabel={true} />
                <BooleanField  source={"hm"} addLabel={true} />
                <NumberField  source={"factor"} addLabel={true} />

                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
